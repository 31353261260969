import React from "react";

function DoorIntercomSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 511.828 511.828"
    >
      <path fill="#f1f4f6" d="M54.931 0h401.967v511.828H54.931z"></path>
      <path fill="#daeaec" d="M255.914 0h200.983v511.828H255.914z"></path>
      <path fill="#c8ffff" d="M129.934 75.004h251.96v181.335h-251.96z"></path>
      <path fill="#9ddff6" d="M255.914 75.004h125.98v181.334h-125.98z"></path>
      <path
        fill="#ff1f3d"
        d="M322.608 256.338v-7.482c0-36.064-29.34-65.404-65.405-65.404h-2.578c-36.064 0-65.405 29.34-65.405 65.405v7.482z"
      ></path>
      <path
        fill="#a20f59"
        d="M322.608 248.856c0-36.064-29.34-65.404-65.405-65.404h-1.289v72.887h66.694z"
      ></path>
      <path
        fill="#0a617d"
        d="M396.894 271.338H114.935V60.003h281.959zm-251.959-30h221.959V90.003H144.935z"
      ></path>
      <circle cx="255.914" cy="151.795" r="43.623" fill="#ffd4af"></circle>
      <path
        fill="#eec298"
        d="M255.914 108.173v87.245c24.054 0 43.623-19.569 43.623-43.623 0-24.053-19.569-43.622-43.623-43.622z"
      ></path>
      <g fill="#0a617d">
        <path d="M114.934 310.208h30.002v30h-30.002zM114.934 362.211h30.002v30h-30.002zM114.934 414.214h30.002v30h-30.002zM164.937 310.208h30.002v30h-30.002zM164.937 362.211h30.002v30h-30.002zM164.937 414.214h30.002v30h-30.002zM214.939 310.208h30.002v30h-30.002zM214.939 362.211h30.002v30h-30.002zM214.939 414.214h30.002v30h-30.002z"></path>
      </g>
      <circle cx="346.561" cy="377.211" r="50.333" fill="#ff7816"></circle>
      <circle cx="346.561" cy="377.211" r="50.333" fill="#ff1f3d"></circle>
      <path
        fill="#08475e"
        d="M396.894 60.003h-140.98v30h110.98v151.335h-110.98v30h140.98z"
      ></path>
    </svg>
  );
}

export default DoorIntercomSvg;