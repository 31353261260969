import React from "react";

function WebSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512pt"
      height="512pt"
      viewBox="0 0 512 512"
    >
      <path
        fill="#00c8c8"
        d="M494.895 163.996c-18.38-47.492-50.403-88.207-92.61-117.738C359.04 15.996 308.453 0 256 0S152.96 15.996 109.719 46.258c-42.207 29.531-74.235 70.246-92.614 117.738-2.988 7.727.852 16.414 8.579 19.402 7.722 2.993 16.41-.847 19.402-8.574C69.09 112.793 119.37 65.344 180 43.398a179.722 179.722 0 00-4.016 5.344c-20.765 28.79-36.66 68.309-45.964 114.281-1.641 8.122 3.609 16.036 11.726 17.676 8.121 1.649 16.035-3.605 17.68-11.722 8.465-41.825 22.601-77.332 40.89-102.688 9.375-12.996 23.325-27.95 40.684-33.777V166c0 8.285 6.715 15 15 15s15-6.715 15-15V32.512c17.36 5.828 31.309 20.781 40.684 33.781 18.285 25.352 32.425 60.86 40.89 102.684 1.442 7.117 7.7 12.027 14.688 12.027.988 0 1.988-.098 2.992-.3 8.121-1.645 13.371-9.56 11.726-17.68-9.304-45.973-25.199-85.493-45.964-114.282A184.959 184.959 0 00332 43.398c60.629 21.946 110.91 69.395 134.914 131.426 2.305 5.946 7.98 9.59 13.992 9.59 1.801 0 3.633-.324 5.414-1.016 7.723-2.988 11.563-11.675 8.575-19.402zm0 0M486.316 328.602c-7.722-2.989-16.41.847-19.402 8.574C442.91 399.207 392.63 446.656 332 468.602a184.959 184.959 0 004.016-5.344c20.765-28.79 36.66-68.309 45.964-114.281 1.645-8.122-3.605-16.036-11.726-17.68-8.125-1.64-16.035 3.61-17.68 11.726-8.465 41.825-22.605 77.332-40.89 102.684-9.38 13-23.325 27.953-40.684 33.781V346c0-8.285-6.719-15-15-15-8.285 0-15 6.715-15 15v133.488c-17.363-5.828-31.309-20.781-40.684-33.781-18.289-25.352-32.43-60.86-40.894-102.684-1.64-8.12-9.555-13.367-17.676-11.726-8.121 1.644-13.371 9.558-11.726 17.68 9.304 45.972 25.199 85.492 45.964 114.28a184.959 184.959 0 004.016 5.345c-60.629-21.946-110.91-69.395-134.914-131.426-2.992-7.727-11.68-11.563-19.402-8.574-7.727 2.992-11.567 11.675-8.579 19.402 18.38 47.492 50.407 88.207 92.614 117.742C152.96 496.004 203.547 512 256 512s103.04-15.996 146.281-46.258c42.207-29.531 74.235-70.246 92.614-117.738 2.988-7.727-.852-16.414-8.579-19.402zm0 0"
      ></path>
      <g fill="#a3d4ff">
        <path d="M76 301a15 15 0 01-13.324-8.113l-31-60c-3.805-7.364-.922-16.41 6.437-20.215 7.364-3.8 16.41-.918 20.211 6.441l17.457 33.782 16.805-33.606c3.703-7.41 12.715-10.41 20.125-6.707 7.406 3.703 10.41 12.715 6.707 20.125l-30 60A15.006 15.006 0 0176.102 301H76zm0 0M286 301a15.003 15.003 0 01-13.418-8.293L256 259.539l-16.582 33.168a15.003 15.003 0 01-26.836 0l-30-60c-3.703-7.41-.7-16.418 6.707-20.125 7.41-3.703 16.422-.7 20.125 6.707L226 252.461l16.582-33.168a15.003 15.003 0 0126.836 0L286 252.461l16.586-33.168c3.703-7.41 12.715-10.414 20.121-6.707 7.41 3.703 10.414 12.715 6.707 20.125l-30 60A14.996 14.996 0 01286 301zm0 0"></path>
        <path d="M436 301h-.102a14.988 14.988 0 01-13.312-8.293L406 259.539l-16.586 33.168C386.875 297.789 381.684 301 376 301s-10.875-3.21-13.414-8.293l-27.95-55.898a14.926 14.926 0 01-4.515-9.836c-.508-8.27 5.785-15.383 14.055-15.891l.906-.055a14.989 14.989 0 0114.332 8.262L376 252.461l16.586-33.168c2.539-5.082 7.73-8.293 13.414-8.293s10.875 3.21 13.414 8.293l16.805 33.605 17.457-33.785c3.8-7.36 12.851-10.242 20.21-6.441 7.36 3.805 10.243 12.851 6.438 20.215l-31 60A15 15 0 01436 301zm0 0M136 301a15.003 15.003 0 01-13.418-8.293l-30-60c-3.703-7.41-.7-16.418 6.707-20.125 7.41-3.703 16.422-.7 20.125 6.707L136 252.461l16.582-33.168a15 15 0 0114.336-8.266l.906.059c8.27.504 14.563 7.617 14.055 15.887a14.934 14.934 0 01-4.516 9.836l-27.949 55.902A14.996 14.996 0 01136 301zm0 0"></path>
      </g>
      <path
        fill="#2864f0"
        d="M467 361H45c-24.813 0-45-20.188-45-45V196c0-24.813 20.188-45 45-45h422c24.813 0 45 20.188 45 45v120c0 24.813-20.188 45-45 45zM45 181c-8.27 0-15 6.73-15 15v120c0 8.27 6.73 15 15 15h422c8.27 0 15-6.73 15-15V196c0-8.27-6.73-15-15-15zm0 0"
      ></path>
    </svg>
  );
}

export default WebSvg;
