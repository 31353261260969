import React from "react";

function AlarmSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 256 256"
    >
      <path
        fill="#808285"
        d="M80.27 212.268c6.351 20.319 25.318 35.066 47.73 35.066s41.379-14.747 47.73-35.066z"
      ></path>
      <path
        fill="#58595b"
        d="M161.332 212.268c-8.637 14.977-24.801 25.066-43.332 25.066a49.773 49.773 0 01-30-10c9.122 12.144 23.643 20 40 20 22.412 0 41.379-14.747 47.73-35.066z"
      ></path>
      <path
        fill="#414042"
        d="M168.857 212.268c-7.702 17.692-25.331 30.066-45.857 30.066-12.534 0-23.982-4.623-32.757-12.243 9.167 10.557 22.677 17.243 37.757 17.243 22.412 0 41.379-14.747 47.73-35.066z"
      ></path>
      <path
        fill="#939598"
        d="M144.697 220.268h-.395a2 2 0 010-4h.395a2 2 0 010 4zm-8.394 0h-43a2 2 0 010-4h43a2 2 0 010 4z"
      ></path>
      <path
        fill="#808285"
        d="M117.333 30.963v-1.63c0-5.881 4.785-10.666 10.667-10.666s10.667 4.785 10.667 10.666v1.63a63.746 63.746 0 0112 3.227v-4.857c0-12.498-10.168-22.666-22.667-22.666s-22.667 10.168-22.667 22.666v4.857a63.897 63.897 0 0112-3.227z"
      ></path>
      <path
        fill="#58595b"
        d="M145.667 29.333v3.212a63.543 63.543 0 015 1.645v-4.857c0-12.498-10.168-22.666-22.667-22.666-.845 0-1.679.05-2.5.141 11.326 1.249 20.167 10.872 20.167 22.525z"
      ></path>
      <path
        fill="#d10028"
        d="M192.267 141.034V94.333c0-35.494-28.773-64.267-64.267-64.267S63.733 58.84 63.733 94.333v46.701a48.721 48.721 0 01-12.794 32.908l-20.404 22.274c-5.629 6.146-1.27 16.051 7.064 16.051h180.802c8.334 0 12.694-9.906 7.064-16.051l-20.404-22.274a48.721 48.721 0 01-12.794-32.908z"
      ></path>
      <g>
        <path
          fill="#b7022d"
          d="M225.465 196.216l-20.404-22.274a48.72 48.72 0 01-12.794-32.908V94.333c0-29.134-19.392-53.726-45.968-61.608 15.757 11.712 25.968 30.467 25.968 51.608v46.701a48.721 48.721 0 0012.794 32.908l20.404 22.274c5.629 6.146 1.27 16.051-7.064 16.051H28.013c-.193 5.064 3.704 10 9.586 10h180.802c8.334.001 12.694-9.905 7.064-16.051z"
        ></path>
      </g>
      <g>
        <path
          fill="#d10028"
          d="M210.475 144.335a5.001 5.001 0 01-4.552-7.064c8.698-19.198 8.698-44.009 0-63.207a5 5 0 119.109-4.127c9.834 21.705 9.834 49.756 0 71.461a5.002 5.002 0 01-4.557 2.937z"
        ></path>
        <path
          fill="#d10028"
          d="M229.475 154.335a5.001 5.001 0 01-4.552-7.064c11.451-25.273 11.451-57.934 0-83.207a5 5 0 119.109-4.127c12.587 27.78 12.587 63.681 0 91.461a5.002 5.002 0 01-4.557 2.937z"
        ></path>
        <g>
          <path
            fill="#d10028"
            d="M45.525 144.335a5 5 0 01-4.557-2.938c-9.834-21.705-9.834-49.755 0-71.461a5.001 5.001 0 019.109 4.127c-8.698 19.199-8.698 44.009 0 63.207a5 5 0 01-4.552 7.065z"
          ></path>
          <path
            fill="#d10028"
            d="M26.525 154.335a5 5 0 01-4.557-2.938c-12.587-27.78-12.587-63.681 0-91.461a5.001 5.001 0 019.109 4.127c-11.451 25.273-11.451 57.934 0 83.207a5 5 0 01-4.552 7.065z"
          ></path>
        </g>
      </g>
      <g>
        <path
          fill="#f1f2f2"
          d="M128 69.069c-7.324 0-13.079 5.976-12.454 12.933l5.064 56.318c.329 3.657 3.539 6.467 7.39 6.467s7.061-2.809 7.389-6.467l5.064-56.318c.626-6.957-5.129-12.933-12.453-12.933zM128 152.845c-6.756 0-12.233 5.221-12.233 11.662s5.477 11.662 12.233 11.662 12.233-5.221 12.233-11.662c.001-6.441-5.477-11.662-12.233-11.662z"
        ></path>
      </g>
    </svg>
  );
}

export default AlarmSvg;
