import React from "react";

function LiveSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path fill="#e9f5ff" d="M238.197 117.717h35.606v51.831h-35.606z"></path>
      <path
        fill="#f58a97"
        d="M358.069 117.68H153.931c-17.069 0-30.905-13.837-30.905-30.905v-55.87C123.025 13.837 136.862 0 153.931 0H358.07c17.069 0 30.905 13.837 30.905 30.905v55.869c0 17.069-13.837 30.906-30.906 30.906z"
      ></path>
      <path
        fill="#f07281"
        d="M358.069 0h-30.906c17.069 0 30.906 13.837 30.906 30.905v55.869c0 17.069-13.837 30.905-30.906 30.905h30.906c17.068 0 30.905-13.837 30.905-30.905V30.905C388.975 13.837 375.138 0 358.069 0z"
      ></path>
      <path
        fill="#fff"
        d="M189.571 82.666h-.044c-4.943.028-10.24.048-14.366.052V27.234a7.726 7.726 0 10-15.452 0v63.159a7.727 7.727 0 006.423 7.616c.554.095 16.263.151 23.483.111a7.727 7.727 0 00-.044-15.454zm27.885-63.158a7.725 7.725 0 00-7.726 7.726v63.212a7.726 7.726 0 1015.452 0V27.234a7.726 7.726 0 00-7.726-7.726zM344.565 82.72h-18.666V66.855h16.719a7.726 7.726 0 100-15.452h-16.719V35.537h18.666a7.726 7.726 0 100-15.452h-26.392a7.725 7.725 0 00-7.726 7.726v62.635a7.726 7.726 0 007.726 7.726h26.392a7.725 7.725 0 007.726-7.726 7.725 7.725 0 00-7.726-7.726zm-51.842-62.703a7.728 7.728 0 00-9.768 4.896l-14.79 44.518L252.639 24.7a7.727 7.727 0 00-14.598 5.067l21.631 62.315a9.16 9.16 0 008.625 6.09h.061c3.893-.026 7.351-2.52 8.622-6.258l20.64-62.129a7.726 7.726 0 00-4.897-9.768z"
      ></path>
      <path
        fill="#3d6d93"
        d="M496.547 481.095H15.453V200.453c0-17.069 13.837-30.905 30.905-30.905h419.284c17.069 0 30.905 13.837 30.905 30.905z"
      ></path>
      <path
        fill="#335e80"
        d="M465.642 169.548h-30.905c17.069 0 30.905 13.837 30.905 30.905v265.189h30.905V200.453c0-17.069-13.837-30.905-30.905-30.905z"
      ></path>
      <path
        fill="#e9f5ff"
        d="M360.368 200.453h98.063a7.205 7.205 0 017.211 7.211v273.431H46.358V207.664a7.205 7.205 0 017.211-7.211h98.063z"
      ></path>
      <path
        fill="#335e80"
        d="M426.067 426.495H85.933a7.726 7.726 0 110-15.452h340.135a7.726 7.726 0 11-.001 15.452z"
      ></path>
      <circle cx="145.771" cy="418.769" r="15.968" fill="#f58a97"></circle>
      <path
        fill="#f58a97"
        d="M318.84 372.41H193.16c-10.517 0-19.042-8.525-19.042-19.042v-73.72c0-10.517 8.525-19.042 19.042-19.042h125.68c10.517 0 19.042 8.525 19.042 19.042v73.72c0 10.517-8.526 19.042-19.042 19.042z"
      ></path>
      <path
        fill="#f07281"
        d="M318.84 260.607h-30.905c10.517 0 19.042 8.525 19.042 19.042v73.72c0 10.517-8.525 19.042-19.042 19.042h30.905c10.517 0 19.042-8.525 19.042-19.042v-73.72c0-10.517-8.526-19.042-19.042-19.042z"
      ></path>
      <path
        fill="#fff"
        d="M274.88 319.72l-33.443 22.477c-2.57 1.727-6.027-.114-6.027-3.211v-44.954c0-3.097 3.457-4.939 6.027-3.211l33.443 22.477c2.281 1.532 2.281 4.889 0 6.422z"
      ></path>
      <path
        fill="#f58a97"
        d="M393.608 370.01a7.727 7.727 0 010-10.927c11.373-11.372 17.636-26.492 17.636-42.575 0-16.082-6.263-31.202-17.636-42.574a7.727 7.727 0 0110.928-10.927c14.291 14.291 22.161 33.291 22.161 53.501s-7.87 39.211-22.161 53.501a7.728 7.728 0 01-10.928.001zm-27.892-27.892a7.726 7.726 0 01.001-10.927c3.912-3.913 6.067-9.128 6.067-14.683s-2.155-10.77-6.068-14.683a7.726 7.726 0 1110.926-10.927c6.833 6.832 10.596 15.927 10.596 25.61.001 9.683-3.762 18.778-10.595 25.609a7.726 7.726 0 01-10.927.001zM107.465 370.01c-14.291-14.291-22.161-33.291-22.161-53.501s7.87-39.21 22.161-53.501a7.727 7.727 0 1110.928 10.927c-11.373 11.372-17.636 26.492-17.636 42.574 0 16.083 6.263 31.203 17.636 42.575a7.727 7.727 0 01-10.928 10.926zm27.892-27.892c-6.833-6.831-10.596-15.927-10.595-25.609 0-9.683 3.763-18.778 10.596-25.61a7.726 7.726 0 1110.926 10.927c-3.913 3.913-6.068 9.128-6.068 14.683s2.155 10.769 6.067 14.683a7.726 7.726 0 11-10.926 10.926z"
      ></path>
      <path
        fill="#335e80"
        d="M504.789 465.642H7.211A7.211 7.211 0 000 472.853C0 494.473 17.527 512 39.147 512h433.706c21.62 0 39.147-17.527 39.147-39.147a7.211 7.211 0 00-7.211-7.211z"
      ></path>
      <path
        fill="#2e5973"
        d="M504.789 465.642h-30.905a7.211 7.211 0 017.211 7.211c0 21.62-17.527 39.147-39.147 39.147h30.905c21.62 0 39.147-17.527 39.147-39.147a7.211 7.211 0 00-7.211-7.211z"
      ></path>
      <path
        fill="#aee69c"
        d="M504.789 225.827H390.691c-1.913 0-3.747.76-5.099 2.112l-21.759 21.759c-2.596 2.596-7.035.757-7.035-2.914v-13.746a7.211 7.211 0 00-7.211-7.211h-17.195a7.211 7.211 0 01-7.211-7.211v-64.4a7.211 7.211 0 017.211-7.211h172.396a7.211 7.211 0 017.211 7.211v64.4a7.21 7.21 0 01-7.21 7.211z"
      ></path>
      <path
        fill="#89daa4"
        d="M504.789 147.004h-30.905a7.211 7.211 0 017.211 7.211v64.4a7.211 7.211 0 01-7.211 7.211h30.905a7.211 7.211 0 007.211-7.211v-64.4a7.212 7.212 0 00-7.211-7.211z"
      ></path>
      <path
        fill="#fff"
        d="M471.362 194.142H365.819a7.726 7.726 0 110-15.452h105.543c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726z"
      ></path>
      <g>
        <path
          fill="#aee69c"
          d="M7.211 225.827h114.097c1.913 0 3.747.76 5.099 2.112l21.759 21.759c2.596 2.596 7.035.757 7.035-2.914v-13.746a7.211 7.211 0 017.211-7.211h17.195a7.211 7.211 0 007.211-7.211v-64.4a7.211 7.211 0 00-7.211-7.211H7.211A7.211 7.211 0 000 154.216v64.4a7.212 7.212 0 007.211 7.211z"
        ></path>
        <path
          fill="#89daa4"
          d="M179.608 147.004h-30.905a7.211 7.211 0 017.211 7.211v64.4a7.211 7.211 0 01-7.211 7.211h-17.195a7.189 7.189 0 00-5.099 2.112l21.759 21.759c2.596 2.596 7.035.757 7.035-2.914v-13.746a7.211 7.211 0 017.211-7.211h17.195a7.211 7.211 0 007.211-7.211v-64.4a7.213 7.213 0 00-7.212-7.211z"
        ></path>
        <path
          fill="#fff"
          d="M146.181 194.142H40.638a7.726 7.726 0 110-15.452h105.543c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726z"
        ></path>
      </g>
    </svg>
  );
}

export default LiveSVG;
