import React from "react";

function LogoDesignSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="512"
    //   height="512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#b3dafe"
        d="M373.386 394.459H324.64V117.541h48.746c4.204 0 7.611-3.407 7.611-7.611s-3.407-7.611-7.611-7.611H324.64V63.432c0-4.204-3.407-7.611-7.612-7.611s-7.611 3.407-7.611 7.611v38.886H71.581V63.432a7.61 7.61 0 10-15.222 0v38.886H7.611a7.61 7.61 0 100 15.222h48.747v276.918H7.611c-4.204 0-7.611 3.407-7.611 7.612s3.408 7.612 7.611 7.612h48.747v38.886a7.61 7.61 0 1015.222 0v-38.886h237.835v38.886c0 4.204 3.407 7.611 7.611 7.611s7.612-3.407 7.612-7.611v-38.886h48.746c4.204 0 7.611-3.407 7.611-7.612s-3.405-7.611-7.609-7.611zm-301.805 0V117.541h237.835v276.918z"
      ></path>
      <path
        fill="#01c0fa"
        d="M309.205 169.702l-103.212-59.643c-9.521-5.501-21.353-5.505-30.878-.012L71.858 169.612c-9.525 5.494-15.468 15.738-15.472 26.734v119.205c-.004 10.995 5.885 21.244 15.406 26.747l103.211 59.642a30.886 30.886 0 0015.45 4.129 30.883 30.883 0 0015.428-4.117l103.258-59.564c9.525-5.495 15.468-15.739 15.472-26.734V196.45c.004-10.996-5.885-21.245-15.406-26.748zm-48.575 54.872v62.905c-.002 5.576-3.016 10.77-7.845 13.555l-54.488 31.431a15.696 15.696 0 01-15.655-.006l-54.464-31.473c-4.828-2.79-7.813-7.986-7.81-13.561V224.52c.002-5.575 3.016-10.769 7.845-13.554l54.488-31.431a15.653 15.653 0 017.823-2.088c2.708 0 5.417.699 7.832 2.094l54.465 31.473c4.826 2.789 7.811 7.985 7.809 13.56z"
      ></path>
      <path
        fill="#0290cf"
        d="M309.205 169.702l-103.212-59.643c-8.379-4.841-18.545-5.421-27.356-1.747a30.582 30.582 0 013.54 1.747l103.212 59.643c9.521 5.502 15.41 15.752 15.405 26.748v119.204c-.004 10.995-5.946 21.24-15.472 26.734l-103.258 59.564a30.718 30.718 0 01-3.521 1.735 30.96 30.96 0 0011.909 2.382 30.883 30.883 0 0015.428-4.117l103.258-59.564c9.525-5.495 15.468-15.739 15.472-26.734V196.45c.005-10.996-5.884-21.245-15.405-26.748z"
      ></path>
      <path
        fill="#fe7ab6"
        d="M499.984 124.882c16.022 16.022 16.022 41.998 0 58.02L487.8 195.086l-58.02-58.02 12.184-12.184c16.022-16.021 41.998-16.021 58.02 0z"
      ></path>
      <path
        fill="#fe3d97"
        d="M499.984 124.882a41.344 41.344 0 00-4.983-4.245c7.822 12.244 3.51 31.89-10.941 46.34l-12.184 12.184 15.924 15.924 12.184-12.184c16.021-16.021 16.021-41.997 0-58.019z"
      ></path>
      <path
        fill="#ffdfcf"
        d="M410.851 155.996l-133.25 133.25-24.759 69.487c-2.944 8.262 5.03 16.236 13.292 13.292l69.487-24.759 133.25-133.25z"
      ></path>
      <path
        fill="#ffcebf"
        d="M451.526 196.989l-133.25 133.25-62.924 31.322c-1.209.602-2.362.936-3.443 1.081.24 6.629 6.97 11.854 13.907 9.383l69.487-24.759 133.25-133.25z"
      ></path>
      <path
        fill="#fe9738"
        d="M277.601 289.245c23.546-4.206 33.216 5.464 29.01 29.01 23.546-4.206 33.216 5.464 29.01 29.01l133.25-133.25-58.02-58.02z"
      ></path>
      <path
        fill="#fe7701"
        d="M335.683 346.884l132.869-132.869-17.026-17.026L328.485 320.03c7.18 4.17 9.577 13.124 7.198 26.854z"
      ></path>
      <path
        fill="#596c76"
        d="M261.46 334.546l-8.618 24.186c-2.944 8.262 5.03 16.236 13.292 13.292l24.186-8.618z"
      ></path>
      <path
        fill="#465a61"
        d="M252.224 362.586c.165 5.378 4.603 9.841 9.971 10.051a10.479 10.479 0 003.62-.613l24.421-8.702-12.77-12.77-22.115 11.008a10.979 10.979 0 01-3.127 1.026z"
      ></path>
      <path
        fill="#fdb97e"
        d="M410.851 155.996l-133.25 133.25c23.546-4.206 33.216 5.464 29.01 29.01l133.25-133.25z"
      ></path>
      <path
        fill="#d8ecfe"
        d="M496.869 186.486l-58.488-58.488c-7.667-7.667-20.097-7.667-27.764 0s-7.667 20.097 0 27.764l58.488 58.488c7.667 7.667 20.097 7.667 27.764 0s7.667-20.098 0-27.764z"
      ></path>
      <path
        fill="#b3dafe"
        d="M496.869 186.486l-16.552-16.552c5.913 5.914 4.492 16.922-3.175 24.589s-18.676 9.088-24.589 3.175l16.552 16.552c7.667 7.667 20.097 7.667 27.764 0s7.667-20.098 0-27.764z"
      ></path>
    </svg>
  );
}

export default LogoDesignSvg;
