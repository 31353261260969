import React from "react";

function IntercomSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512pt"
      height="512pt"
      viewBox="0 -46 512 512"
    >
      <path
        fill="#3aaaff"
        d="M497 420H15c-8.285 0-15-6.715-15-15V15C0 6.715 6.715 0 15 0h482c8.285 0 15 6.715 15 15v390c0 8.285-6.715 15-15 15zm0 0"
      ></path>
      <path
        fill="#0082ff"
        d="M497 0H255v420h242c8.285 0 15-6.715 15-15V15c0-8.285-6.715-15-15-15zm0 0"
      ></path>
      <path
        fill="#c3fdff"
        d="M300 360H45c-8.285 0-15-6.715-15-15V75c0-8.285 6.715-15 15-15h255c8.285 0 15 6.715 15 15v270c0 8.285-6.715 15-15 15zm0 0"
      ></path>
      <g fill="#e79d45" fillRule="evenodd">
        <path d="M386.75 105.75c8.262 0 15-6.738 15-15s-6.738-15-15-15-15 6.738-15 15 6.738 15 15 15zm0 0M447.75 105.75c8.262 0 15-6.738 15-15s-6.738-15-15-15-15 6.738-15 15 6.738 15 15 15zm0 0M386.75 165.75c8.262 0 15-6.738 15-15s-6.738-15-15-15-15 6.738-15 15 6.738 15 15 15zm0 0M447.75 165.75c8.262 0 15-6.738 15-15s-6.738-15-15-15-15 6.738-15 15 6.738 15 15 15zm0 0M386.75 225.75c8.262 0 15-6.738 15-15s-6.738-15-15-15-15 6.738-15 15 6.738 15 15 15zm0 0M447.75 225.75c8.262 0 15-6.738 15-15s-6.738-15-15-15-15 6.738-15 15 6.738 15 15 15zm0 0"></path>
      </g>
      <path
        fill="#00347b"
        d="M417.75 359.25c-24.813 0-45-20.188-45-45s20.188-45 45-45 45 20.188 45 45-20.188 45-45 45zm0 0"
      ></path>
      <path
        fill="#df5b5b"
        d="M232.5 360h-120c-8.285 0-15-6.715-15-15v-35.5c0-41.355 33.645-75 75-75s75 33.645 75 75V345c0 8.285-6.715 15-15 15zm0 0"
      ></path>
      <path
        fill="#ffbb85"
        d="M174 235.25c-33.086 0-60-26.914-60-60s26.914-60 60-60 60 26.914 60 60c0 33.082-26.914 60-60 60zm0 0"
      ></path>
      <path
        fill="#66faff"
        d="M300 60h-45v300h45c8.285 0 15-6.715 15-15V75c0-8.285-6.715-15-15-15zm0 0"
      ></path>
    </svg>
  );
}

export default IntercomSvg;
