import React from "react";

function WifiSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 511.999 511.999"
      version="1.1"
      viewBox="0 0 511.999 511.999"
      xmlSpace="preserve"
    >
      <g fill="#1DE8F1">
        <path d="M492.177 102.686C424.371 55.882 342.761 30.98 256.657 30.98h-1.501c-85.808.3-167.516 25.203-235.324 71.707C9.335 110.188 2.131 121.589.333 134.19c-1.501 12.3 2.098 24.903 10.2 34.504 15.301 18.001 42.305 21.601 61.504 8.1 52.505-36.302 116.214-55.504 183.119-55.805h1.501c67.206 0 130.808 19.202 183.313 55.805 19.506 13.501 46.505 9.902 61.507-8.1 17.103-20.104 12.902-50.706-9.3-66.008z"></path>
        <path d="M402.169 195.994c-42.906-29.402-93.609-45.004-147.014-45.004s-105.109 15.602-147.714 45.004C85.84 210.695 82.24 241.298 98.142 261.4c14.1 18.003 39.302 21.901 58.203 9.001 28.201-19.201 64.009-28.802 98.809-28.802s69.608 9.601 97.809 28.802c19.202 12.901 44.103 9.002 58.504-9.001 15.903-20.402 11.702-51.005-9.298-65.406z"></path>
        <path d="M325.66 286.903c-21.301-10.502-45.901-15.602-70.505-15.602s-50.205 5.099-71.506 15.602c-15.902 7.501-24.901 23.701-24.901 40.203a44.916 44.916 0 0010.196 28.503c13.203 16.201 36.705 21.303 54.706 12.3 9.303-4.499 20.404-6.9 31.504-6.9 11.101 0 22.202 2.401 31.504 6.9 18.001 9.002 40.503 3.9 53.706-12.3 18.299-22.501 11.099-56.406-14.704-68.706zM255.155 391.012c-24.904 0-46.004 20.101-46.004 45.004s21.1 45.004 46.004 45.004 45.004-20.101 45.004-45.004-20.099-45.004-45.004-45.004z"></path>
      </g>
      <g fill="#1BD9DE">
        <path d="M300.159 436.016c0 24.903-20.1 45.004-45.004 45.004v-90.008c24.905 0 45.004 20.102 45.004 45.004zM340.365 355.61c-13.203 16.201-35.705 21.303-53.706 12.3-9.303-4.499-20.404-6.9-31.504-6.9v-89.708c24.604 0 49.205 5.099 70.505 15.602 25.803 12.299 33.003 46.204 14.705 68.706zM411.468 261.4c-14.4 18.003-39.302 21.901-58.504 9.001-28.201-19.201-63.009-28.802-97.809-28.802V150.99c53.406 0 104.109 15.602 147.014 45.004 21 14.401 25.201 45.004 9.299 65.406zM501.476 168.693c-15.001 18.001-42.001 21.601-61.507 8.1-52.505-36.602-116.107-55.805-183.313-55.805h-1.501V30.979h1.501c86.104 0 167.714 24.903 235.521 71.707 22.202 15.302 26.403 45.904 9.299 66.007z"></path>
      </g>
    </svg>
  );
}

export default WifiSVG;
