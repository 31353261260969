import React from "react";

function FireSVG() {
  return (
    <svg
                className="text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="512"
                height="512"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#f44336"
                  d="M12 18.75c0-1.437.812-2.687 2-3.316a4.503 4.503 0 013.533-4.322c-.551-2.063-1.502-3.881-2.384-5.548-.951-1.796-1.85-3.493-1.984-5.104a.5.5 0 00-.777-.373C7.25 3.554 7.329 8.551 7.717 10.914 6.115 9.478 5.487 7.386 5.48 7.36a.498.498 0 00-.33-.337.507.507 0 00-.463.086C4.578 7.197 2 9.319 2 14.5c0 4.416 3.29 7.5 8 7.5a9.83 9.83 0 002-.207z"
                ></path>
                <path
                  fill="#d43a2f"
                  d="M12 .358C7.254 3.81 7.339 8.612 7.717 10.914 6.115 9.478 5.487 7.386 5.48 7.36A.498.498 0 005.001 7a.508.508 0 00-.314.109C4.578 7.197 2 9.319 2 14.5c0 4.416 3.29 7.5 8 7.5a9.83 9.83 0 002-.207V18.75z"
                ></path>
                <path
                  fill="#607d8b"
                  d="M21.25 18.25a.75.75 0 01-.75-.75v-2a1 1 0 00-2 0v2a.75.75 0 01-1.5 0v-2c0-1.379 1.121-2.5 2.5-2.5s2.5 1.121 2.5 2.5v2a.75.75 0 01-.75.75z"
                ></path>
                <path
                  fill="#ffc107"
                  d="M22.25 17h-5.5c-.965 0-1.75.785-1.75 1.75v3.5c0 .965.785 1.75 1.75 1.75h5.5c.965 0 1.75-.785 1.75-1.75v-3.5c0-.965-.785-1.75-1.75-1.75z"
                ></path>
                <path
                  fill="#546d79"
                  d="M19.5 13a2.502 2.502 0 00-2.5 2.5V17h1.5v-1.5a1 1 0 011-1z"
                ></path>
                <path
                  fill="#dea806"
                  d="M19.5 17h-2.75c-.965 0-1.75.785-1.75 1.75v3.5c0 .965.785 1.75 1.75 1.75h2.75z"
                ></path>
              </svg>
  );
}

export default FireSVG;
