import React from "react";

function WiresSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512.003 512.003"
    >
      <path
        fill="#5bc980"
        d="M121.021 270.004c-.517 0-1.037-.027-1.562-.081-8.241-.852-14.229-8.224-13.378-16.464 6.767-65.408 28.226-129.786 62.059-186.174 4.262-7.103 13.473-9.408 20.58-5.145 7.104 4.262 9.407 13.476 5.145 20.58-31.589 52.648-51.625 112.756-57.941 173.826-.799 7.716-7.313 13.458-14.903 13.458z"
      ></path>
      <path
        fill="#6aa9ff"
        d="M121.008 270.003l-.27-.002c-8.282-.146-14.879-6.979-14.733-15.262a1152.237 1152.237 0 0130.396-243.171c1.897-8.064 9.973-13.062 18.037-11.166 8.064 1.898 13.063 9.973 11.166 18.037A1122.19 1122.19 0 00136 255.268c-.144 8.192-6.831 14.735-14.992 14.735z"
      ></path>
      <path
        fill="#ffb64c"
        d="M120.984 270.004c-7.591 0-14.104-5.742-14.902-13.458-6.316-61.07-26.353-121.178-57.941-173.826-4.263-7.104-1.959-16.318 5.145-20.58 7.104-4.264 16.318-1.958 20.58 5.145 33.833 56.388 55.292 120.766 62.059 186.174.852 8.24-5.137 15.612-13.378 16.464-.525.055-1.047.081-1.563.081z"
      ></path>
      <path
        fill="#ff6c6c"
        d="M120.998 270.003c-8.162 0-14.849-6.543-14.993-14.736A1122.068 1122.068 0 0076.401 18.438C74.504 10.375 79.503 2.3 87.567.402c8.064-1.896 16.14 3.101 18.037 11.166C124.333 91.16 134.559 172.974 136 254.739c.146 8.283-6.451 15.116-14.733 15.262l-.269.002z"
      ></path>
      <path
        fill="#eff6fe"
        d="M316.003 113.783l-14.56 1.55 14.56 40.89v47.56l-11.44 7.33 10.49 34.16c-2.14 5.68-7.62 9.73-14.05 9.73h-60c-8.28 0-15-6.72-15-15v-83.78l9.44-11.78-9.44-30.66v-47.56l9.44-18-9.44-24.44v-8.78c0-8.28 6.72-15 15-15h60c8.28 0 15 6.72 15 15z"
      ></path>
      <path
        fill="#d2dce7"
        d="M316.003 113.783l-14.56 1.55 14.56 40.89v47.56l-11.44 7.33 10.49 34.16c-2.14 5.68-7.62 9.73-14.05 9.73h-30v-255h30c8.28 0 15 6.72 15 15z"
      ></path>
      <path
        fill="#d2dce7"
        d="M316.003 113.783v42.44l-90-90v-42.44zm0 90v36.22c0 1.85-.34 3.63-.95 5.27l-89.05-89.05v-42.44z"
      ></path>
      <path
        fill="#a1aebc"
        d="M316.003 113.783v42.44l-45-45v-42.44zm-45 45l45 45v36.22c0 1.85-.34 3.63-.95 5.27l-44.05-44.05z"
      ></path>
      <path
        fill="#fed843"
        d="M436.003 15.003v240c0 8.28-6.72 15-15 15s-15-6.72-15-15v-240c0-8.28 6.72-15 15-15s15 6.72 15 15z"
      ></path>
      <path
        fill="#ffb64c"
        d="M436.003 15.003v240c0 8.28-6.72 15-15 15v-270c8.28 0 15 6.72 15 15z"
      ></path>
      <path
        fill="#6e6e6e"
        d="M451.003 240.003h-60c-8.28 0-15 6.72-15 15v242c0 8.28 6.72 15 15 15h60c8.28 0 15-6.72 15-15v-242c0-8.28-6.72-15-15-15z"
      ></path>
      <path
        fill="#444"
        d="M466.003 255.003v242c0 8.28-6.72 15-15 15h-30v-272h30c8.28 0 15 6.72 15 15z"
      ></path>
      <path
        fill="#6e6e6e"
        d="M151.003 240.003h-60c-8.28 0-15 6.72-15 15v242c0 8.28 6.72 15 15 15h60c8.28 0 15-6.72 15-15v-242c0-8.28-6.72-15-15-15z"
      ></path>
      <path
        fill="#444"
        d="M166.003 255.003v242c0 8.28-6.72 15-15 15h-30v-272h30c8.28 0 15 6.72 15 15z"
      ></path>
      <path
        fill="#6e6e6e"
        d="M331.003 240.003h-120c-8.28 0-15 6.72-15 15v242c0 8.28 6.72 15 15 15h120c8.28 0 15-6.72 15-15v-242c0-8.28-6.72-15-15-15z"
      ></path>
      <path
        fill="#444"
        d="M346.003 255.003v242c0 8.28-6.72 15-15 15h-60v-272h60c8.28 0 15 6.72 15 15z"
      ></path>
    </svg>
  );
}

export default WiresSVG;
