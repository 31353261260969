import React from "react";

function RouterSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ariaHidden="true"
      viewBox="0 0 512 512"
    >
      <path
        fill="#455A64"
        d="M373.333 322.532c-5.891 0-10.667-4.776-10.667-10.667V162.532c0-5.891 4.776-10.667 10.667-10.667S384 156.641 384 162.532v149.333c0 5.891-4.776 10.667-10.667 10.667z"
      ></path>
      <path
        fill="#CFD8DC"
        d="M53.333 301.177h405.333c29.455 0 53.333 23.878 53.333 53.333v42.667c0 29.455-23.878 53.333-53.333 53.333H53.333C23.878 450.511 0 426.632 0 397.177V354.51c0-29.455 23.878-53.333 53.333-53.333z"
      ></path>
      <g fill="#4CAF50">
        <circle cx="74.667" cy="375.865" r="10.667"></circle>
        <circle cx="138.667" cy="375.865" r="10.667"></circle>
        <circle cx="202.667" cy="375.865" r="10.667"></circle>
        <circle cx="266.667" cy="375.865" r="10.667"></circle>
      </g>
      <path
        fill="#455A64"
        d="M437.333 386.532H330.667c-5.891 0-10.667-4.776-10.667-10.667s4.776-10.667 10.667-10.667h106.667c5.891 0 10.667 4.776 10.667 10.667s-4.777 10.667-10.668 10.667z"
      ></path>
      <g fill="#2196F3">
        <path d="M343.168 203.385a10.663 10.663 0 01-7.552-3.115c-20.831-20.825-20.835-54.594-.01-75.425l.01-.01c4.093-4.237 10.845-4.354 15.083-.262 4.237 4.093 4.354 10.845.262 15.083-.086.089-.173.176-.262.262-12.501 12.493-12.507 32.754-.014 45.255l.014.014c4.171 4.16 4.179 10.914.019 15.085a10.667 10.667 0 01-7.571 3.134l.021-.021zm60.331 0a10.667 10.667 0 01-7.552-18.219c12.501-12.493 12.507-32.754.014-45.255l-.014-.014c-4.093-4.237-3.975-10.99.262-15.083a10.666 10.666 0 0114.821 0c20.831 20.825 20.835 54.594.01 75.425l-.01.01a10.667 10.667 0 01-7.531 3.136z"></path>
        <path d="M312.981 233.551a10.663 10.663 0 01-7.552-3.115c-37.432-37.515-37.432-98.251 0-135.765a10.668 10.668 0 0115.083 15.083c-29.161 29.158-29.163 76.434-.005 105.595l.005.005c4.171 4.16 4.179 10.914.019 15.085a10.667 10.667 0 01-7.571 3.134l.021-.022zm120.704 0a10.667 10.667 0 01-7.552-18.219c29.161-29.158 29.163-76.434.005-105.595l-.005-.005c-4.093-4.237-3.975-10.99.262-15.083a10.666 10.666 0 0114.821 0c37.432 37.515 37.432 98.251 0 135.765a10.67 10.67 0 01-7.531 3.137z"></path>
        <path d="M282.816 263.716a10.663 10.663 0 01-7.552-3.115c-54.147-54.152-54.147-141.944 0-196.096 4.237-4.093 10.99-3.975 15.083.262 3.992 4.134 3.992 10.687 0 14.82-45.819 45.821-45.819 120.11 0 165.931 4.171 4.16 4.179 10.914.019 15.085a10.667 10.667 0 01-7.571 3.134l.021-.021zm181.035 0a10.667 10.667 0 01-7.552-18.219c45.819-45.821 45.819-120.11 0-165.931-4.093-4.237-3.976-10.99.262-15.083a10.666 10.666 0 0114.821 0c54.147 54.152 54.147 141.944 0 196.096a10.664 10.664 0 01-7.531 3.137z"></path>
      </g>
    </svg>
  );
}

export default RouterSVG;
