import React from "react";

function HdSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512pt"
      height="512pt"
      viewBox="0 -36 512 512"
    >
      <linearGradient
        id="a"
        x1="256"
        x2="256"
        y1="0"
        y2="440"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2af598"></stop>
        <stop offset="1" stopColor="#009efd"></stop>
      </linearGradient>
      <path
        fill="url(#a)"
        d="M452 0H60C26.914 0 0 26.914 0 60v240c0 33.086 26.914 60 60 60h176v40H132v40h248v-40H276v-40h176c33.086 0 60-26.914 60-60V60c0-33.086-26.914-60-60-60zm20 300c0 11.027-8.973 20-20 20H60c-11.027 0-20-8.973-20-20V60c0-11.027 8.973-20 20-20h392c11.027 0 20 8.973 20 20zM196 100.121h40v160h-40v-60h-40v60h-40v-160h40v60h40zm140 0h-60v160h60c33.086 0 60-26.914 60-60v-40c0-33.082-26.914-60-60-60zm20 100c0 11.027-8.973 20-20 20h-20v-80h20c11.027 0 20 8.973 20 20zm0 0"
      ></path>
    </svg>
  );
}

export default HdSVG;
