import React from "react";

function AntennaSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <linearGradient
        id="SVGID_1_"
        x1="256"
        x2="256"
        y1="512"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5558ff"></stop>
        <stop offset="1" stopColor="#00c0ff"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M167.442 304.171c6.982 4.456 16.257 2.412 20.715-4.572 4.457-6.983 2.41-16.257-4.572-20.715C144.396 253.867 121 211.293 121 165c0-74.439 60.561-135 135-135s135 60.561 135 135c0 46.293-23.396 88.867-62.585 113.884-6.982 4.458-9.029 13.732-4.572 20.715 4.452 6.974 13.725 9.035 20.715 4.572C392.424 273.614 421 221.587 421 165 421 74.019 346.981 0 256 0S91 74.019 91 165c0 56.587 28.576 108.614 76.442 139.171zm145.724-68.118c6.203 5.536 15.684 4.954 21.179-1.205C351.534 215.587 361 190.781 361 165c0-57.897-47.103-105-105-105s-105 47.103-105 105c0 25.781 9.466 50.587 26.655 69.849 5.516 6.182 14.998 6.719 21.179 1.205 6.181-5.516 6.72-14.998 1.204-21.179C187.762 201.116 181 183.404 181 165c0-41.355 33.645-75 75-75s75 33.645 75 75c0 18.404-6.762 36.116-19.038 49.874-5.516 6.181-4.977 15.663 1.204 21.179zM406 482h-77.687l-54.749-275.571C289.675 199.573 301 183.584 301 165c0-24.813-20.187-45-45-45s-45 20.187-45 45c0 18.584 11.325 34.573 27.436 41.429L183.687 482H106c-8.284 0-15 6.716-15 15s6.716 15 15 15h300c8.284 0 15-6.716 15-15s-6.716-15-15-15zM256 150c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15zm-41.726 332L256 271.976 297.726 482z"
      ></path>
    </svg>
  );
}

export default AntennaSVG;
