import React from "react";

function BrandsSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      ariaHidden="true"
      className="w-5 h-5"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BrandsSVG;
