import React from "react";

function ControlSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#ffc89a"
        d="M24.701 198.789L1.976 238.558A15 15 0 000 246v210c0 8.284 6.716 15 15 15h110V176c0-8.284-6.716-15-15-15H89.819c-26.846 0-51.798 14.48-65.118 37.789z"
      ></path>
      <path
        fill="#0a617d"
        d="M385 512H155c-24.853 0-45-20.147-45-45V45c0-24.853 20.147-45 45-45h230c24.853 0 45 20.147 45 45v422c0 24.853-20.147 45-45 45z"
      ></path>
      <path
        fill="#08475e"
        d="M385 0H270v512h115c24.853 0 45-20.147 45-45V45c0-24.853-20.147-45-45-45z"
      ></path>
      <path
        fill="#fab68c"
        d="M473.25 238.5h-64.5c-21.401 0-38.75-17.349-38.75-38.75S387.349 161 408.75 161h64.5c21.401 0 38.75 17.349 38.75 38.75s-17.349 38.75-38.75 38.75zm0 77.5h-64.5c-21.401 0-38.75-17.349-38.75-38.75s17.349-38.75 38.75-38.75h64.5c21.401 0 38.75 17.349 38.75 38.75S494.651 316 473.25 316zm0 77.5h-64.5c-21.401 0-38.75-17.349-38.75-38.75S387.349 316 408.75 316h64.5c21.401 0 38.75 17.349 38.75 38.75s-17.349 38.75-38.75 38.75zm0 77.5h-64.5c-21.401 0-38.75-17.349-38.75-38.75s17.349-38.75 38.75-38.75h64.5c21.401 0 38.75 17.349 38.75 38.75S494.651 471 473.25 471z"
      ></path>
      <path
        fill="#ff6378"
        d="M270 266.5c-35.841 0-65-29.159-65-65s29.159-65 65-65 65 29.159 65 65-29.159 65-65 65z"
      ></path>
      <circle cx="210" cy="80.5" r="15" fill="#ffe278"></circle>
      <circle cx="270" cy="80.5" r="15" fill="#ff6378"></circle>
      <circle cx="330" cy="80.5" r="15" fill="#00a763"></circle>
      <path
        fill="#85dc1e"
        d="M270 446.5c-35.841 0-65-29.159-65-65s29.159-65 65-65 65 29.159 65 65-29.159 65-65 65z"
      ></path>
      <path
        fill="#e20059"
        d="M270 136.5v130c35.841 0 65-29.159 65-65s-29.159-65-65-65z"
      ></path>
      <path
        fill="#00a763"
        d="M270 316.5v130c35.841 0 65-29.159 65-65s-29.159-65-65-65z"
      ></path>
      <path
        fill="#e20059"
        d="M270 65.5v30c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
      ></path>
      <path
        fill="#ffc89a"
        d="M110 331c29.677-8.903 50-36.218 50-67.201V238.5h71.25c21.401 0 38.75-17.349 38.75-38.75S252.651 161 231.25 161H89.819c-26.847 0-51.798 14.48-65.118 37.789L1.976 238.558"
      ></path>
    </svg>
  );
}

export default ControlSVG;
