import React from "react";

function BrandingSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#e6f3ff"
        d="M391 45v361l-75 106H15c-8.401 0-15-6.601-15-15V45c0-8.401 6.599-15 15-15h361c8.401 0 15 6.599 15 15z"
      ></path>
      <path
        fill="#cce6ff"
        d="M391 45v361l-75 106H196V30h180c8.401 0 15 6.599 15 15z"
      ></path>
      <path
        fill="#2e3e99"
        d="M271 436c0 8.399-6.599 15-15 15h-30c-10.499 0-20.7-2.1-30-6.301-26.4-11.4-45-37.8-45-68.699v-30c0-24.901 20.099-45 45-45h60c8.401 0 15 6.599 15 15 0 8.399-6.599 15-15 15h-60c-8.401 0-15 6.599-15 15v30c0 13.2 5.7 25.199 15 33.3 7.8 7.2 18.3 11.7 30 11.7h30c8.401 0 15 6.599 15 15z"
      ></path>
      <path
        fill="#3d6dcc"
        d="M106 91c-8.291 0-15-6.709-15-15V15c0-8.291 6.709-15 15-15s15 6.709 15 15v61c0 8.291-6.709 15-15 15zM211 15v61c0 8.399-6.599 15-15 15s-15-6.601-15-15V15c0-8.401 6.599-15 15-15s15 6.599 15 15z"
      ></path>
      <path
        fill="#2e3e99"
        d="M286 91c-8.291 0-15-6.709-15-15V15c0-8.291 6.709-15 15-15s15 6.709 15 15v61c0 8.291-6.709 15-15 15z"
      ></path>
      <path
        fill="#b3ceff"
        d="M316 151h-60c-8.291 0-15-6.709-15-15s6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15zM316 211h-60c-8.291 0-15-6.709-15-15s6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
      ></path>
      <path
        fill="#2e3e99"
        d="M196 91V0c8.401 0 15 6.599 15 15v61c0 8.399-6.599 15-15 15z"
      ></path>
      <path
        fill="#b8e65c"
        d="M136 271c-41.367 0-75-33.647-75-75s33.633-75 75-75 75 33.647 75 75-33.633 75-75 75z"
      ></path>
      <path
        fill="#3d6dcc"
        d="M497 241H256c-8.401 0-15 6.599-15 15v180c0 41.4 33.6 76 75 76h120c41.4 0 76-34.6 76-76V256c0-8.401-6.599-15-15-15z"
      ></path>
      <path
        fill="#2e3e99"
        d="M512 256v180c0 41.4-34.6 76-76 76h-60V241h121c8.401 0 15 6.599 15 15z"
      ></path>
      <path
        fill="#b8e65c"
        d="M376 301c-41.4 0-75 33.6-75 75s33.6 75 75 75 75-33.6 75-75-33.6-75-75-75z"
      ></path>
      <path
        fill="#7bcc29"
        d="M451 376c0 41.4-33.6 75-75 75V301c41.4 0 75 33.6 75 75z"
      ></path>
    </svg>
  );
}

export default BrandingSvg;
