import React from "react";

function CameraSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 128 128"
    >
      <path
        fill="#17ace8"
        d="M28.345 30.863l68.68 39.654-5.977 10.353a8 8 0 01-10.928 2.928L25.296 52.144a8 8 0 01-2.928-10.928l5.977-10.353z"
      ></path>
      <path
        fill="#0d70b2"
        d="M48.254 12.382l77.993 45.031-12.98 22.482-84.921-49.031 8.98-15.554a8 8 0 0110.928-2.928z"
      ></path>
      <path
        fill="#fedb41"
        d="M91.051 80.871a8.127 8.127 0 01-.574.848c.022.013.04.028.061.04a12.98 12.98 0 0017.731-4.751l-11.241-6.49z"
      ></path>
      <path
        fill="#00ddc1"
        d="M104.207 59.675a4.98 4.98 0 106.8-1.823 4.98 4.98 0 00-6.8 1.823z"
      ></path>
      <path
        fill="#e1e4fb"
        d="M44.627 53.537a1.74 1.74 0 01-.873-.235L29.41 45.02a1.75 1.75 0 111.75-3.031L45.5 50.27a1.751 1.751 0 01-.877 3.267z"
      ></path>
      <path
        fill="#0d70b2"
        d="M1.75 57.373H7a6 6 0 016 6v47.317a6 6 0 01-6 6H1.75V57.373z"
      ></path>
      <path fill="#c5c9f7" d="M13 67.381h11.25v36H13z"></path>
      <path
        fill="#e1e4fb"
        d="M53.567 68.467l-5.051 8.612a8.034 8.034 0 01-6.9 3.953H24.25v12h17.364a20.078 20.078 0 0017.252-9.881l5.093-8.684z"
      ></path>
    </svg>
  );
}

export default CameraSVG;
