import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

// svgs
import BrandsSVG from "../svg/brands.js";
import CctvSVG from "../svg/camera.js";
import ControlSVG from "../svg/control.js";
import HdSVG from "../svg/hd.js";
import LiveSVG from "../svg/live.js";
import WiresSVG from "../svg/wires.js";
import RouterSVG from "../svg/router";
import WifiSVG from "../svg/wifi";
import AntennaSVG from "../svg/antenna";
import IntercomSvg from "../svg/intercomSvg";
import DoorIntercomSvg from "../svg/doorIntercomSvg";
import FireSvg from "../svg/fireSvg";
import AlarmSvg from "../svg/alarmSvg";
import SmartLockSvg from "../svg/smartLock";
import LogoSvg from "../svg/logoDesignSvg";
import IdentitySvg from "../svg/brandingSvg";
import WebSvg from "../svg/webSvg";

function ServicesPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `cctv`,
          `surveillance system`,
          `security System`,
          `kishangarh`,
          `ajmer`,
          `jalore`,
          `fire alarm`,
        ]}
        title="8bit Systems - Services - CCTV, Networking"
      />
      <section
        className="text-gray-700 h-5/6 body-font bg-center bg-cover absolute w-full left-0"
        style={{
          top: "90px",
          backgroundImage: `url(https://images.unsplash.com/photo-1589935447067-5531094415d1?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=60)`,
        }}
      >
        <div className="h-full w-full absolute bg-white opacity-25"></div>
        <div className="container px-8 pt-72 mx-auto lg:px-4 z-10">
          <div className="flex flex-col w-full mb-12 text-left lg:text-center">
            <h2 className="mb-1 text-xs font-bold tracking-widest text-brandOrange-200 uppercase title-font">
              a great header right here
            </h2>
            <h1
              style={{ lineHeight: "1.28", zIndex: "50" }}
              className="z-10 mb-6 text-2xl font-semibold tracking-tighter text-black md:text-6xl title-font"
            >
              Services and solutions
            </h1>
            <p className="mx-auto text-base font-medium leading-relaxed text-gray-700 lg:w-2/3">
              Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
              gentrify, subway tile poke farm-to-table. Franzen you probably
              havet heard of them man bun deep.
            </p>
          </div>
          <div className="flex lg:justify-center">
            <button className="inline-flex px-4 py-2 font-semibold text-white transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-blue-700 hover:from-blue-600 to-blue-600 hover:to-blue-700 focus:shadow-outline focus:outline-none">
              Button
            </button>
          </div>
        </div>
      </section>
      <section
        id="surveillance-cctv"
        className="container px-4 py-20 mx-auto"
        style={{ marginTop: "83.33vh" }}
      >
        <h2 className="mb-1 text-3xl md:text-5xl font-extrabold leading-tight text-gray-900">
          Surveillance System (CCTV)
        </h2>
        <p className="mb-16 text-lg text-gray-500">
          Get insights to dig down into whats powering your growth the most.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-x-16 lg:gap-x-24 gap-y-20">
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-red-600">
              <BrandsSVG />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Renowned Brands
            </h3>
            <p className="text-sm text-gray-500">
              We use devices from world{`'`}s renowed brands like hikivision,
              cpplus, Tenda, D-Link etc. to ensure the best quality delivered to
              you.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-pink-600">
              <WiresSVG />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Wired & Wireless Configuration
            </h3>
            <p className="text-sm text-gray-500">
              Setup of both wired connection and wireless connection for the
              CCTV cameras and its devices like DVR, NVR.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-yellow-600">
              <LiveSVG />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Online live view
            </h3>
            <p className="text-sm text-gray-500">
              Securely watch live recording of your CCTVs from a distant place
              from your mobile or PC
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-green-600 ">
              <CctvSVG />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              PTZ Cameras
            </h3>
            <p className="text-sm text-gray-500">
              We offer installation and mentainance of PTZ cameras, A PTZ camera
              works by moving the camera in different directions to get a whole
              picture of the surveillance area and zooming in for further detail
              of security events.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-purple-600">
              <HdSVG />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              High Definition and Stable Imaging
            </h3>
            <p className="text-sm text-gray-500">
              Devices with support for 1080p (full HD) and 4k (ultra HD)
              technologies.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-blue-600">
              <ControlSVG />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Easy Control and Monitoring
            </h3>
            <p className="text-sm text-gray-500">
              Remotely control your CCTV System from anywhere in the world. Give
              instant access to your family and dear ones.
            </p>
          </div>
        </div>
      </section>
      <section
        id="networking-system"
        className="container px-4 py-20 mx-auto"
        // style={{ marginTop: "83.33vh" }}
      >
        <h2 className="mb-1 text-3xl md:text-5xl font-extrabold leading-tight text-gray-900">
          Networking Systems
        </h2>
        <p className="mb-16 text-lg text-gray-500">
          Get insights to dig down into whats powering your growth the most.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-x-16 lg:gap-x-24 gap-y-20">
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-red-600">
              <WifiSVG />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              WiFi Solutions
            </h3>
            <p className="text-sm text-gray-500">
              Meet the wireless needs of your business/building/institute by
              providing workers/staff or students a safe wireless high-speed
              internet connectivity.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-pink-600">
              <RouterSVG />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Routing Solutions
            </h3>
            <p className="text-sm text-gray-500">
              We help you Modernize your building or plant{`'`}s network by
              providing end-to-end structured cabling to strengthen data, voice
              and video transmission.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-yellow-600">
              <AntennaSVG />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Point to Point and Point to multi point Systems
            </h3>
            <p className="text-sm text-gray-500">
              8 Bit Systems also specializes in providing a private wireless
              network between two or more points (building, offices, institutes
              etc) using point to point or point to multi point.
            </p>
          </div>
        </div>
      </section>
      <section
        id="intercom-system"
        className="container px-4 py-20 mx-auto"
        // style={{ marginTop: "83.33vh" }}
      >
        <h2 className="mb-1 text-3xl md:text-5xl font-extrabold leading-tight text-gray-900">
          Intercom System
        </h2>
        <p className="mb-16 text-lg text-gray-500">
          Get insights to dig down into whats powering your growth the most.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-x-16 lg:gap-x-24 gap-y-20">
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-red-600">
              <IntercomSvg />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Wired & Wireless intercom
            </h3>
            <p className="text-sm text-gray-500">
              We offer end-to-end design, installation, repair and maintenance
              of wired and wireless intercom systems.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-pink-600">
              <DoorIntercomSvg />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Door Answering Intercoms
            </h3>
            <p className="text-sm text-gray-500">
              Doorbell intercom systems allow you to talk to visits using your
              own telephone. An intercom or door buzzer entry system is
              installed outside where a normal doorbell would be. When a
              visitors rings the bell, your phone rings (usually with a
              distinctive ring) – and then you can talk to the person at your
              front door.
            </p>
          </div>
        </div>
      </section>
      <section
        id="fire-security-system"
        className="container px-4 py-20 mx-auto"
        // style={{ marginTop: "83.33vh" }}
      >
        <h2 className="mb-1 text-3xl md:text-5xl font-extrabold leading-tight text-gray-900">
          Fire Alarm and Security System
        </h2>
        <p className="mb-16 text-lg text-gray-500">
          Get insights to dig down into whats powering your growth the most.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-x-16 lg:gap-x-24 gap-y-20">
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-red-600">
              <FireSvg />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Fire Alarm System
            </h3>
            <p className="text-sm text-gray-500">
              We help you equip your building/home with system to detect heat,
              smoke, and carbon monoxide, ensuring you will always be aware of a
              problem in your building.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-pink-600">
              <AlarmSvg />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Intrusion Alarm System
            </h3>
            <p className="text-sm text-gray-500">
              Installing a business alarm system helps you keep burglars out,
              reduce employee theft, stop unauthorized access and prevent
              shoplifting.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-pink-600">
              <SmartLockSvg />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Smart Lock System
            </h3>
            <p className="text-sm text-gray-500">
              8bit Systems offers electronic smart locks for homes and
              businesses. Using your smartphone, smart card, or pin code instead
              of keys.
            </p>
          </div>
        </div>
      </section>
      <section
        id="design-solutions"
        className="container px-4 py-20 mx-auto"
        // style={{ marginTop: "83.33vh" }}
      >
        <h2 className="mb-1 text-3xl md:text-5xl font-extrabold leading-tight text-gray-900">
          Design
        </h2>
        <p className="mb-16 text-lg text-gray-500">
          Get insights to dig down into whats powering your growth the most.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-x-16 lg:gap-x-24 gap-y-20">
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-red-600">
              <LogoSvg />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Logo Design
            </h3>
            <p className="text-sm text-gray-500">
              You know your business. We know design. Work with us to create a
              logo that leaves a lasting impression.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-pink-600">
              <IdentitySvg />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Identity Design
            </h3>
            <p className="text-sm text-gray-500">
              Logo Design, Website Design, business Card, Email Design,
              Brochure, Menus etc.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-pink-600">
              <IdentitySvg />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Stationary Design
            </h3>
            <p className="text-sm text-gray-500">
              letterhead, envelopes, envelope stickers, compliments slips,
              invoices, receipts and much more.
            </p>
          </div>
        </div>
      </section>
      <section
        id="website-development"
        className="container px-4 py-20 mx-auto"
        // style={{ marginTop: "83.33vh" }}
      >
        <h2 className="mb-1 text-3xl md:text-5xl font-extrabold leading-tight text-gray-900">
          Website Developement
        </h2>
        <p className="mb-16 text-lg text-gray-500">
          Get insights to dig down into whats powering your growth the most.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-x-16 lg:gap-x-24 gap-y-20">
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-red-600">
              <LogoSvg />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Web Design
            </h3>
            <p className="text-sm text-gray-500">
              UI design for web and mobile applications.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center w-12 h-12 mb-4 text-pink-600">
              <WebSvg />
            </div>
            <h3 className="mb-2 text-base font-semibold leading-tight text-gray-900">
              Website Developement
            </h3>
            <p className="text-sm text-gray-500">
              We offer Developement work for static, dynamic and hybrid web
              applications.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ServicesPage;
