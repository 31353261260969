import React from "react";

function SmartLockSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <linearGradient
        id="New_Gradient_Swatch_13"
        x1="-31.098"
        x2="335.098"
        y1="439.098"
        y2="72.902"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#d46669"></stop>
        <stop offset="1" stopColor="#dd888a"></stop>
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_15"
        x1="98.285"
        x2="205.715"
        y1="437.715"
        y2="330.285"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015" stopColor="#cacad5"></stop>
        <stop offset="1" stopColor="#dcdce3"></stop>
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_14"
        x1="115.248"
        x2="188.752"
        y1="420.752"
        y2="347.248"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015" stopColor="#a6a6b9"></stop>
        <stop offset="1" stopColor="#cacad5"></stop>
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_3"
        x1="211.994"
        x2="413.923"
        y1="484.965"
        y2="283.035"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.011" stopColor="#606082"></stop>
        <stop offset="1" stopColor="#83839e"></stop>
      </linearGradient>
      <g data-name="12-lock">
        <g>
          <rect
            width="280"
            height="504"
            x="12"
            y="4"
            fill="url(#New_Gradient_Swatch_13)"
            rx="44"
          ></rect>
          <circle
            cx="152"
            cy="384"
            r="76"
            fill="url(#New_Gradient_Swatch_15)"
          ></circle>
          <circle
            cx="152"
            cy="384"
            r="52"
            fill="url(#New_Gradient_Swatch_14)"
          ></circle>
          <path
            fill="url(#New_Gradient_Swatch_3)"
            d="M464 348h-.3l-320 8a12 12 0 00-11.7 12v32a12 12 0 0011.7 12l320 8h.3a36 36 0 000-72z"
          ></path>
          <circle cx="152.551" cy="164" r="12.002" fill="#70ccc4"></circle>
          <path
            fill="#90d7d1"
            d="M120.01 130.424a12 12 0 01-6.991-21.761 68.044 68.044 0 0177.727-.933 12 12 0 01-13.492 19.849 44.045 44.045 0 00-50.273.6 11.944 11.944 0 01-6.971 2.245z"
          ></path>
          <path
            fill="#afe2de"
            d="M88.008 94.444a12 12 0 01-7.46-21.406 116.052 116.052 0 01142.778-.959 12 12 0 11-14.652 19.008 92.052 92.052 0 00-113.222.763 11.954 11.954 0 01-7.444 2.594z"
          ></path>
          <g fill="#f1cccd">
            <circle cx="152" cy="220" r="12"></circle>
            <circle cx="152" cy="267" r="12"></circle>
            <circle cx="204" cy="220" r="12"></circle>
            <circle cx="204" cy="267" r="12"></circle>
            <circle cx="100" cy="220" r="12"></circle>
            <circle cx="100" cy="267" r="12"></circle>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SmartLockSvg;
